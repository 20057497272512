// extracted by mini-css-extract-plugin
export var insuranceBenefitsSection = "K_gp";
export var insuranceBusinessesSection = "K_gm";
export var insuranceCaseStudySection = "K_gr";
export var insuranceClientQuotesSection = "K_gz";
export var insuranceDevExpertiseSection = "K_gw";
export var insuranceDevServicesIconSection = "K_gl";
export var insuranceOurAchievementsSection = "K_gy";
export var insuranceProcessSection = "K_gn";
export var insuranceRequirementsSection = "K_gs";
export var insuranceSkilledSection = "K_gx";
export var insuranceSuccessStoriesSection = "K_gt";
export var insuranceTechStackSection = "K_gq";
export var insuranceWhyHireSection = "K_gv";